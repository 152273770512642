<template>
  <div>
    <div>
      <div class="sctp-container clear">
        <div class="pad-t10">
          <el-breadcrumb separator=">">
            <el-breadcrumb-item>登录/注册</el-breadcrumb-item>
            <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="view-nav">
          <div class="view-nav-item" :class="{'nav-active': $route.path === '/account-appeal/submit'}">
            <router-link :to="'/account-appeal/submit'">账号申诉</router-link>
          </div>
          <div class="view-nav-item" :class="{'nav-active': $route.path === '/account-appeal/query'}">
            <router-link :to="'/account-appeal/query'">申诉结果查询</router-link>
          </div>
          <div class="view-nav-item" :class="{'nav-active': $route.path === '/account-appeal/reset'}">
            <router-link :to="'/account-appeal/reset'">重置资料</router-link>
          </div>
          <div class="view-nav-item" :class="{'nav-active': $route.path === '/account-appeal/help'}">
            <router-link :to="'/account-appeal/help'">申诉帮助</router-link>
          </div>
        </div>
        <div class="view-container">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'accountAppeal',
  components: {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.view-nav {
  float: left;
  margin: 10px 5px 10px 0;
  width: 170px;
  background: #FFF;
  font-size: 13px;
  .view-nav-item:not(:first-child) {
    border-top: 1px dotted #c8c8c8;
  }
}

.view-nav-item {
  height: 40px;
  line-height: 40px;
  color: rgba(41, 43, 51, 0.8);
  text-align: center;
}

.nav-active, .view-nav-item:hover {
  background: #F90;
}

.router-link-exact-active {
  color: #f9f7f4 !important;
}

.nav-active a, .view-nav-item:hover a {
  color: #FFFFFF;
}

.view-container {
  float: left;
  margin: 10px 0 10px 5px;
  width: calc(100% - 180px);
  background: #FFF;
  min-height: 60vh;
}
</style>
